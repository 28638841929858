var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","plain":"","icon":"","to":{
                name:
                  _vm.type_bill == 1 ? 'facturacion' : 'facturacion_servicios',
              }}},on),[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Átras')}})]),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(
            _vm.$route.meta.title + (_vm.loading ? '' : ' (' + _vm.tbl_data.length + ')')
          )}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})])],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-select',{attrs:{"label":"Estado","items":_vm.tbl_filters,"item-value":"id","item-text":function (v) { return v.value; },"dense":"","disabled":_vm.loading},on:{"change":_vm.getTableData},model:{value:(_vm.tbl_filter),callback:function ($$v) {_vm.tbl_filter=$$v},expression:"tbl_filter"}})],1),(_vm.tbl_data.length > 0)?_c('v-col',{attrs:{"cols":"12","xs":"12","md":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","hide-details":"","dense":""},model:{value:(_vm.tbl_search),callback:function ($$v) {_vm.tbl_search=$$v},expression:"tbl_search"}})],1):_vm._e()],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.tbl_headers,"search":_vm.tbl_search,"items":_vm.tbl_data,"loading":_vm.loading,"items-per-page":15,"dense":""},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
          var item = ref.item;
return [_c('b',{domProps:{"textContent":_vm._s(item.key)}})]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","dark":"","x-small":"","color":(item.response ? 'green' : 'orange') + ' darken-1'},on:{"click":function($event){return _vm.billCommentDlg(item)}}},on),[_c('v-icon',[_vm._v(" mdi-comment-"+_vm._s(item.discharged ? "check" : !item.response ? "arrow-right" : "arrow-left")+" ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Detalle')}})]),(item.response && !item.discharged)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","dark":"","x-small":"","color":"warning"},on:{"click":function($event){return _vm.billCommentDischarged(item)}}},on),[_c('v-icon',[_vm._v(" mdi-comment-check ")])],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Terminar')}})]):_vm._e()],1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","persistent":"","max-width":"800"},model:{value:(_vm.bill_comment_dlg),callback:function ($$v) {_vm.bill_comment_dlg=$$v},expression:"bill_comment_dlg"}},[_c('v-card',{attrs:{"tile":"","disabled":_vm.bill_comment_dlg_ldg,"loading":_vm.bill_comment_dlg_ldg}},[_c('v-toolbar',{attrs:{"dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.folio)+" | COMENTARIOS ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.bill_comment_dlg = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1)],1),(_vm.bill_comment_dlg)?_c('v-card-text',[_c('v-row',{staticClass:"pt-4"},[(_vm.bill_comment)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-timeline',{attrs:{"align-top":"","dense":""}},_vm._l((_vm.bill_comment.bill_comment_details),function(item,i){return _c('v-timeline-item',{key:i,attrs:{"color":(item.response ? 'green' : 'orange') + ' darken-1',"icon":'mdi-' +
                  (_vm.bill_comment &&
                  _vm.bill_comment.discharged &&
                  _vm.bill_comment.bill_comment_details.length - 1 == i
                    ? 'check'
                    : 'arrow-' + (item.response ? 'left' : 'right')),"small":""}},[_c('div',[_c('div',{staticClass:"font-weight-light"},[_vm._v(" "+_vm._s(item.created_at)+" | "+_vm._s(item.user_email)+" ")]),(item.bill_comment_type_name)?_c('div',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(item.bill_comment_type_name)}}):_vm._e(),_c('div',{domProps:{"textContent":_vm._s(item.detail)}})])])}),1)],1):_vm._e(),(_vm.bill_comment_detail)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"bill_comment_detail_form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',{staticClass:"px-5 mx-5",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Detalle","rows":"2","dense":"","rules":_vm.rules.required},model:{value:(_vm.bill_comment_detail.detail),callback:function ($$v) {_vm.$set(_vm.bill_comment_detail, "detail", $$v)},expression:"bill_comment_detail.detail"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"block":"","dark":"","color":"green darken-1"},on:{"click":function($event){$event.preventDefault();return _vm.billCommentSubmit.apply(null, arguments)}}},[_vm._v(" Responder "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v(" mdi-comment-arrow-left ")])],1)],1)],1)],1)],1):_vm._e()],1)],1):_vm._e()],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }